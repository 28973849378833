import React from "react"
import { PageProps, graphql, Link } from "gatsby"
import Image, { FluidObject } from "gatsby-image"
import { css } from "@emotion/core"

import Layout from "components/layout"
import SEO from "components/seo"
import Tequila from "content/assets/tequila.svg"
import ScrollIcon from "components/scroll-icon"

type FluidImage = {
  childImageSharp: { fluid: FluidObject }
}

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  prismic: {
    allMenu_categorys: {
      edges: {
        node: {
          menu_category_name: string
        }
      }[]
    }
  }
  logo: FluidImage
  heroImage: FluidImage
  bushesImage: FluidImage
  mojitosImage: FluidImage
  horsesImage: FluidImage
  horseRidersImage: FluidImage
  womanHorseImage: FluidImage
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const firstCategory = React.useMemo(() => {
    const categories: string[] = data.prismic.allMenu_categorys.edges.map(
      ({ node: { menu_category_name } }) => menu_category_name
    )
    return categories[0]
  }, [])
  const menuLink = `/menu/${firstCategory.toLowerCase()}`

  return (
    <Layout location={location}>
      <SEO title="An Authentic Mexican Restaurant" />
      <section
        css={css`
          height: 90vh;
        `}
        className="relative"
      >
        <Image
          css={css`
            height: 90vh;
          `}
          fluid={data.heroImage.childImageSharp.fluid}
        />
        <div className="absolute top-0 flex justify-center w-full mt-6 opacity-0 md:hidden animation-fade-in-down animation-0.5s animation-delay-0.3s animation-once animation-fill-forwards">
          <Image className="w-32" fluid={data.logo.childImageSharp.fluid} />
        </div>
        <header className="absolute inset-0 flex flex-col items-center justify-center max-w-screen-xl mx-auto text-white">
          <h1 className="text-center leading-tight text-4xl sm:text-5xl lg:text-7xl font-bold tracking-wider font-display animation-fade-in-down animation-0.5s opacity-0 animation-delay-0.5s animation-once animation-fill-forwards">
            An Authentic <span className="text-mustard"> Mexican </span>
            Restaurant
          </h1>
          <span className="animation-0.5s mt-12 animation-delay-0.7s animation-fade-in-down opacity-0 animation-once animation-fill-forwards">
            <Link className="text-xl btn btn-mustard" to={menuLink}>
              View menu
            </Link>
          </span>
        </header>
        <div className="absolute inset-x-0 bottom-0 flex justify-center mb-8 text-white animation-0.5s animation-delay-0.9s animation-fade-in-down opacity-0 animation-once animation-fill-forwards">
          <ScrollIcon label="Read more" scrollTo="#info" />
        </div>
      </section>
      <section className="flex flex-col items-center max-w-screen-xl px-8 py-12 mx-auto lg:flex-row">
        <header id="info" className="py-12 md:pr-24">
          <div className="w-2/3 h-3 rounded-full bg-orange">&nbsp;</div>
          <h1 className="mt-12 text-6xl font-medium tracking-wide font-display text-dark-brown">
            Right from tequila’s homeland
          </h1>
          <p className="mt-4 text-gray-700">
            The tequila was born in Jalisco, Mexico, just like us! That’s why in
            Los Agaves Grill we have some top notch tequila for you!
          </p>

          <button className="self-start mt-12 text-lg font-semibold btn btn-mustard">
            <Link to={menuLink}>Check out our menu for more!</Link>
          </button>
        </header>
        <Image
          className="w-full rounded-md shadow-md lg:w-2/3"
          fluid={data.bushesImage.childImageSharp.fluid}
        />
      </section>
      <div className="max-w-screen-xl px-5 py-12 mx-auto sm:py-24 sm:px-0 md:px-12 lg:px-24 xl:px-48">
        <Tequila />
      </div>
      <section className="max-w-screen-xl pb-20 mx-auto md:px-4 lg:px-24 xl:px-48">
        <h1
          className="my-20 text-lg font-bold text-center text-orange"
          css={css`
            font-weight: 900;
            font-size: 4rem;

            @media screen and (min-width: 768px) {
              font-size: 5rem;
            }
          `}
        >
          Don't miss <u>Viernes</u> de mojitos!
        </h1>
        <div className="relative px-4 md:px-0">
          <Image
            css={css`
              min-height: 20rem;
            `}
            className="rounded-lg shadow-lg"
            fluid={data.mojitosImage.childImageSharp.fluid}
          />
          <div
            css={css`
              & > *:not(:last-child) {
                margin-bottom: 2rem;
              }
            `}
            className="absolute inset-0 top-0 flex flex-col items-center justify-center px-12 md:px-24"
          >
            <h1 className="text-2xl font-bold text-center text-white md:text-6xl">
              Órale!
            </h1>
            <p className="text-lg font-semibold text-center text-white md:text-3xl ">
              Bring your señorita and enjoy <u>2 mojitos for the price of 1</u>{" "}
              every friday after 6 pm.
            </p>
          </div>
        </div>
      </section>
      <section className="px-8 py-12 lg:py-24 bg-dark-green-4">
        <div className="flex flex-col-reverse max-w-screen-xl mx-auto lg:flex-row">
          <div className="relative flex-1 mt-6 lg:mt-0 lg:px-12">
            <div
              css={css`
                &:hover div:not(:hover) {
                  transform: scale(0.95);

                  @media screen and (min-width: 1024px) {
                    transform: translate(-50%, -50%) scale(0.95);
                  }
                }

                & > div {
                  @media screen and (min-width: 1024px) {
                    transform: translate(-50%, -50%);
                    float: none;
                    height: initial;
                  }

                  float: left;
                  height: 6rem;

                  &:hover {
                    transform: scale(1.05);

                    @media screen and (min-width: 1024px) {
                      transform: translate(-50%, -50%) scale(1.05);
                    }
                  }
                }
              `}
            >
              <Image
                css={css`
                  left: 0;
                  top: 1rem;

                  @media screen and (min-width: 1024px) {
                    left: auto;
                    top: auto;

                    bottom: 2rem;
                    right: -6rem;
                    position: absolute !important;
                  }
                `}
                imgStyle={{
                  objectFit: "fill",
                }}
                className="z-10 w-1/3 transition duration-150 ease-out transform rounded-md shadow-xl sm:w-1/3 lg:w-3/4 hover:shadow-2xl hover:z-20"
                fluid={data.horsesImage.childImageSharp.fluid}
              />
              <Image
                css={css`
                  top: -1rem;
                  transform: scale(1.3);

                  @media screen and (min-width: 1024px) {
                    top: auto;

                    bottom: -7rem;
                    right: 6rem;
                    position: absolute !important;
                    transform: scale(1);
                  }
                `}
                className="z-20 w-1/3 transition duration-150 ease-out transform rounded-md shadow-xl lg:z-10 sm:w-1/3 lg:w-1/2 hover:shadow-2xl hover:z-20"
                fluid={data.womanHorseImage.childImageSharp.fluid}
              />
              <Image
                css={css`
                  right: 0;
                  top: 1rem;

                  @media screen and (min-width: 1024px) {
                    top: auto;

                    right: -3rem;
                    bottom: -2rem;
                    position: absolute !important;
                  }
                `}
                className="absolute z-10 w-1/3 transition duration-150 ease-out transform rounded-md shadow-xl sm:w-1/3 lg:w-5/12 hover:shadow-2xl hover:z-20"
                fluid={data.horseRidersImage.childImageSharp.fluid}
              />
            </div>
          </div>
          <header className="flex-1 py-10 sm:pl-12 sm:py-12">
            <div className="w-2/3 h-3 rounded-full bg-orange">&nbsp;</div>
            <h1 className="mt-12 text-6xl font-medium tracking-wide font-display text-dark-brown">
              A place full of tradition and flavours
            </h1>
            <p className="mt-4 text-gray-700">
              Did you know that in Mexico we not only have the best flavours and
              rhythm, but also some fun sports like <b>charreria</b>! Here at
              Los Agaves Grill you’ll be able enjoy it as much as we do!
            </p>
          </header>
        </div>
      </section>
      <section className="flex flex-col items-center justify-between max-w-screen-xl p-12 py-16 mx-auto sm:flex-row">
        <header>
          <h1 className="text-5xl font-extrabold text-orange">
            Ready to taste Mexico?
          </h1>
          <h3 className="text-3xl">Check out our menu.</h3>
        </header>
        <Link
          className="self-center flex-shrink-0 mt-6 text-lg btn btn-mustard sm:mt-0"
          to={menuLink}
        >
          View menu
        </Link>
      </section>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    prismic {
      allMenu_categorys {
        edges {
          node {
            menu_category_name
          }
        }
      }
    }
    logo: file(absolutePath: { regex: "/logo.png/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    heroImage: file(absolutePath: { regex: "/hero-image.png/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bushesImage: file(absolutePath: { regex: "/bushes.png/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mojitosImage: file(absolutePath: { regex: "/mojitos.png/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    horsesImage: file(absolutePath: { regex: "/horses.png/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    horseRidersImage: file(absolutePath: { regex: "/horse-riders.png/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    womanHorseImage: file(absolutePath: { regex: "/woman-horse.png/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
